import React, {useEffect} from 'react';
import {navigate} from '@reach/router';

const Pabbly = () => {
  useEffect(() => {
    navigate('/pabbly-connect');
  }, []);

  return <></>;
};

export default Pabbly;
